<template>
  <div id="settings-wrapper">
    <v-card
      id="settings"
      class="py-2 px-3"
      color="rgba(0, 0, 0, .3)"
      dark
      flat
      link
      min-width="75"
      :style="cogStyle"
    >
      <v-icon>
        mdi-cog
      </v-icon>
    </v-card>

    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      activator="#settings"
      bottom
      content-class="v-settings"
      left
      nudge-left="25"
      origin="top right"
      transition="slide-x-reverse-transition"
    >
      <v-card
        class="text-center mb-0"
        width="300"
      >
        <v-card-title>
          {{ $t('settings.title')}}
          <v-spacer/>
          <v-btn icon @click="menu=false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <strong class="mb-3 d-inline-block">SIDEBAR FILTERS</strong>

          <v-item-group v-model="color">
            <v-item
              v-for="color in colors"
              :key="color"
              :value="color"
            >
              <template v-slot="{ active, toggle }">
                <v-avatar
                  :class="active && 'v-settings__item--active'"
                  :color="color"
                  class="v-settings__item"
                  size="25"
                  @click="toggle"
                />
              </template>
            </v-item>
          </v-item-group>

          <v-divider class="my-4 secondary" />

          <v-row
            align="center"
            no-gutters
          >
            <v-col cols="auto">
              Dark Mode
            </v-col>

            <v-spacer />

            <v-col cols="auto">
              <v-switch
                v-model="dark"
                class="ma-0 pa-0"
                color="secondary"
                hide-details
              />
            </v-col>
          </v-row>

          <v-divider class="my-4 secondary" />

          <v-row
            align="center"
            no-gutters
          >
            <v-col cols="auto">
              Sidebar Image
            </v-col>

            <v-spacer />

            <v-col cols="auto">
              <v-switch
                v-model="showImg"
                class="ma-0 pa-0"
                color="secondary"
                hide-details
              />
            </v-col>
          </v-row>

          <v-divider class="my-4 secondary" />

          <v-row
            align="center"
            no-gutters
          >
            <v-col cols="auto">
              Small Menu
            </v-col>

            <v-spacer />

            <v-col cols="auto">
              <v-switch
                v-model="smallMenu"
                class="ma-0 pa-0"
                color="secondary"
                hide-details
              />
            </v-col>
          </v-row>

          <v-divider class="my-4 secondary" />

          <strong class="mb-3 d-inline-block">IMAGES</strong>

          <v-item-group
            v-model="image"
            class="d-flex justify-space-between mb-3"
          >
            <v-item
              v-for="image in images"
              :key="image"
              :value="image"
              class="mx-1"
            >
              <template v-slot="{ active, toggle }">
                <v-sheet
                  :class="active && 'v-settings__item--active'"
                  class="d-inline-block v-settings__item"
                  @click="toggle"
                >
                  <v-img
                    :src="image"
                    height="100"
                    width="50"
                  />
                </v-sheet>
              </template>
            </v-item>
          </v-item-group>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
  // Mixins
  import Proxyable from 'vuetify/lib/mixins/proxyable'
  import { mapMutations, mapState } from 'vuex'
  import store from '@/store'

  export default {
    name: 'DashboardCoreSettings',

    mixins: [Proxyable],

    data: () => ({
      dark:false,
      cogStyle:{
        position: "fixed",
        bottom: "115px",
        right: "-35px",
        borderRadius: "8px",
        zIndex:"1"
      },
      color: '#004159',
      colors: [
        '#9C27b0',
        '#00CAE3',
        '#4CAF50',
        '#ff9800',
        '#E91E63',
        '#FF5252',
        '#004159'
      ],
      image: require('@/assets/Rose-Petals.svg'),
      images: [
        require('@/assets/Rose-Petals.svg'),
        require('@/assets/Flat-Mountains.svg'),
        require('@/assets/Bullseye-Gradient.svg'),
        require('@/assets/Hollowed-Boxes.svg'),
      ],
      menu: false,
      saveImage: '',
      showImg: true,
      smallMenu: true,
    }),

    computed: {
      ...mapState({
        barImage: state => state.settings.barImage
      }),
    },

    watch: {
      color (val) {
        this.$vuetify.theme.themes[this.isDark ? 'dark' : 'light'].primary = val
        store.commit('settings/SET_PRIMARY', val)
      },
      dark(val){
        this.$vuetify.theme.dark = val
        store.commit('settings/SET_DARK', val)
      },
      showImg (val) {
        if (!val) {
          this.saveImage = this.barImage
          this.setBarImage('')
        } else if (this.saveImage) {
          this.setBarImage(this.saveImage)
          this.saveImage = ''
        } else {
          this.setBarImage(this.image)
        }
      },
      image (val) {
        if (this.showImg && val){
          this.setBarImage(val)
        }
      },
      smallMenu(val){
        this.setSmallMenu(val)
      }
    },

    mounted(){
      this.dark = store.state.settings.darkMode
      let primary = store.state.settings.primaryColor
      this.color = primary ? primary: this.color
    },

    methods: {
      ...mapMutations({
        setBarImage: 'settings/SET_BAR_IMAGE',
        setSmallMenu: 'settings/SET_SMALL_MENU'
      }),
    },
  }
</script>

<style lang="sass">
  .v-settings
    .v-item-group > *
      cursor: pointer

    &__item
      border-width: 3px
      border-style: solid
      border-color: transparent !important

      &--active
        border-color: #00cae3 !important
</style>
